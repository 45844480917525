import React from "react";
import styles from "./tours.module.css";
import text from "./toursText";
import LanguageContext from "../../context/context";

const Tours = () => {
  return (
    <LanguageContext.Consumer>
      {({ language }) => {
        return (
          <section className={styles.container}>
            <div className={styles.item}>
              <h2>{text[language].weekendH}</h2>
              <p>{text[language].weekendP1}</p>
              <p>{text[language].weekendP2}</p>
              <p>{text[language].weekendP3}</p>
            </div>

            <div className={styles.item}>
              <h2> {text[language].palmH}</h2>
              <p>{text[language].palmP1}</p>
              <p>{text[language].palmP2}</p>
              <p>{text[language].palmP3}</p>
            </div>

            <div className={styles.item}>
              <h2> {text[language].individualH}</h2>
              <p>{text[language].individualP1}</p>
              <p>{text[language].individualP2}</p>
            </div>
          </section>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Tours;
