import React from "react";
import styles from "./contact.module.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { FaHome, FaPhoneAlt, FaViber } from "react-icons/fa";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { IoLogoWhatsapp } from 'react-icons/io'

const position = [41.690781, 44.8236954];

const Contact = (props) => {
  return (
    <section className={styles.container}>
      <div className={styles.item}>
        <MapContainer
          center={position}
          zoom={13}
          language={"english"}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png"
          />
          <Marker position={position}>
            <Popup>
              We are here
              <br />
            </Popup>
          </Marker>
        </MapContainer>
      </div>
      <div className={styles.item}>
        <div className={styles.contact}>
          <div className={styles.contact_item}>
            <div>
              <FaHome className={styles.icon} />
            </div>
            <h2>Georgia, Tbilisi, Nioradze street 19</h2>
          </div>


          <div className={styles.contact_item}>
            <IoLogoWhatsapp className={`${styles.icon} ${styles.whatsapp}`} />
            <a href="https://wa.me/995593326928">+995 593 32 69 28</a>
          </div>

          <div className={styles.contact_item}>
            <FaPhoneAlt className={styles.icon} />
            <a href="tel:+995322770988">+995 322 77 09 88</a>
          </div>

          <div className={styles.contact_item}>
            <IoLogoWhatsapp className={`${styles.icon} ${styles.whatsapp}`} style={{ marginLeft: '1px' }} />
            <a href="https://wa.me/79037209007">+7 903 720 900 7</a>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Contact;
