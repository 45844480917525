import React, { useState } from "react";
import styles from "./header.module.css";
import { Link } from "react-router-dom";

import LanguageSwitch from "../languageSwitch/languageSwitch";

import LanguageContext from "../../context/context";

const text = {
  en: {
    logo: "Hotel Heyvany",
    home: "HOME",
    tours: "TOURS",
    services: "SERVICES",
    gallery: "GALLERY",
    rooms: "ROOMS",
    contact: "CONTACT",
  },
  ru: {
    logo: "Гостиница хейвани",
    home: "ГЛАВНАЯ",
    tours: "ТУРЫ",
    services: "сервис",
    gallery: "ГАЛЕРЕЯ",
    rooms: "НОМЕРА",
    contact: "ОБРАТНАЯ СВЯЗЬ",
  },
};

const Header = ({ homePage, contactPage }) => {
  const [current, setCurrent] = useState(false);

  const toggleMenu = () => {
    setCurrent(!current);
  };

  return (
    <LanguageContext.Consumer>
      {({ language }) => {
        return (
          <header
            className={
              `${homePage ? styles.nav : styles.nav_other} ` +
              `${contactPage ? styles.contacts : ""}`
            }
          >
            <div className={styles.container}>
              <div>
                <Link to="/" className={`${styles.logo}`}>{text[language].logo}</Link>
              </div>
              <div onClick={toggleMenu} className={styles.toggle_button}>
                <div className={homePage ? styles.bar_home : styles.bar}></div>
                <div className={homePage ? styles.bar_home : styles.bar}></div>
                <div className={homePage ? styles.bar_home : styles.bar}></div>
              </div>
              <div
                className={`${current ? styles.active : ""} ${homePage ? styles.nav_item_home : styles.nav_item
                  }  `}
              >
                <Link to="/rooms">{text[language].rooms}</Link>
                <Link to="/services">{text[language].services}</Link>
                <Link to="/tours">{text[language].tours}</Link>
                <Link to="/gallery">{text[language].gallery}</Link>
                <Link to="/contact">{text[language].contact}</Link>

                <LanguageSwitch home={homePage ? true : false} />
              </div>
            </div>
          </header>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Header;
