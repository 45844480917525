import React from "react";
import styles from "./servicesPage.module.css";
import Header from "../../components/header/header";
import Services from "../../components/services/services";
import ServiceImages from '../../components/serviceImages/serviceImages'
const ServicesPage = (props) => {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <Services />
        <ServiceImages/>
      </div>
    </div>
  );
};

export default ServicesPage;
