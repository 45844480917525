import { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ToursPage from "./pages/toursPage/toursPage";
import homePage from "./pages/homePage/homePage";
import RoomsPage from "./pages/roomsPage/roomsPage";
import GalleryPage from "./pages/galleryPage/galleryPage";
import ContactPage from "./pages/contactPage/contactPage";
import ServicesPage from "./pages/servicesPage/servicesPage";
import Footer from "./components/footer/footer";
import { FaWhatsappSquare } from "react-icons/fa";
import LanguageContext from "./context/context";

function App() {
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    if (localStorage.getItem("language")) {
      setLanguage(localStorage.getItem("language"));
    }
  }, []);
  const value = { language, setLanguage };

  return (
    <LanguageContext.Provider value={value}>
      <div className="app">
        <Router>
          <Route exact component={ContactPage} path="/contact" />
          <Route exact component={GalleryPage} path="/gallery" />
          <Route exact component={ServicesPage} path="/services" />
          <Route exact component={RoomsPage} path="/rooms" />
          <Route exact component={ToursPage} path="/tours" />
          <Route exact component={homePage} path="/" />
        </Router>

        <Footer />
        <div className="contact"><a href="https://wa.me/995593326928"><FaWhatsappSquare color="yellow" /></a></div>
      </div>
    </LanguageContext.Provider>
  );
}

export default App;
