import React from "react";
import styles from "./tourImages.module.css";

import img1 from '../../images/tours/1.webp'
import img2 from '../../images/tours/2.webp'
import img3 from '../../images/tours/3.webp'
import img4 from '../../images/tours/4.webp'
import img5 from '../../images/tours/5.webp'
const data = [img1, img2, img3, img4, img5];

const tourImages = () => {
  return (
    <section className={styles.container}>
      {data.map((image, index) => {
        return (
          <div key={index} className={styles.item}>
            <img className={styles.image} src={image} alt="Hotel Heivany" />
          </div>
        );
      })}
    </section>
  );
};

export default tourImages;
