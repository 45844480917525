import React from "react";
import styles from "./contactPage.module.css";
import Header from "../../components/header/header";
import Contact from "../../components/contact/contact";

const ContactPage = () => {
  return (
    <div className={styles.container}>
      <Header />
      <div>
        <Contact />
      </div>
    </div>
  );
};

export default ContactPage;
