import React, { useContext } from "react";
import styles from "./languageSwitch.module.css";
import LanguageContext from "../../context/context";

const LanguageSwitch = ({ home }) => {
  const { language, setLanguage } = useContext(LanguageContext);

  const handleSetLanguage = (language) => {
    setLanguage(language);

    // if (localStorage.getItem('language')) {
    // 	handleSetLanguage(localStorage.getItem('language'))
    // }

    localStorage.setItem("language", language);
  };

  return (
    <button
      className={`${styles.container} ${home ? "" : styles.container_other}`}
      value={language === "en" ? "ru" : "en"}
      onClick={(e) => handleSetLanguage(e.target.value)}
    >
      {language === "en" ? "RUSSIAN" : "ENGLISH"}
    </button>
  );
};

export default LanguageSwitch;
