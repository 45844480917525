import first from "../../images/rooms/basic_double_room.jpg";
import second from "../../images/rooms/double_room.jpg";
import third from "../../images/rooms/double_room_with_balcony.jpg";
import fourth from "../../images/rooms/family_room.jpg";
import fifth from "../../images/rooms/family_suite.jpg";
import sixth from "../../images/rooms/room_with_bunk_bed.jpg";
import seventh from "../../images/rooms/room_with_bunk_bed2.jpg";
import eigtht from "../../images/rooms/triple_bed.jpg";
import ninth from "../../images/rooms/twin_room_balcony.jpg";
import img1 from '../../images/rooms_gallery/Budget Double Room/1.webp'
import img2 from '../../images/rooms_gallery/Budget Double Room/2.webp'
import img3 from '../../images/rooms_gallery/Budget Double Room/3.webp'
import img4 from '../../images/rooms_gallery/Budget Double Room/4.webp'

import img5 from '../../images/rooms_gallery/Budget Triple Room/1.webp'
import img6 from '../../images/rooms_gallery/Budget Triple Room/2.webp'
import img7 from '../../images/rooms_gallery/Budget Triple Room/3.webp'
import img8 from '../../images/rooms_gallery/Budget Triple Room/4.webp'
import img9 from '../../images/rooms_gallery/Budget Triple Room/5.webp'

import img10 from '../../images/rooms_gallery/Classic Triple Room/1.webp'
import img11 from '../../images/rooms_gallery/Classic Triple Room/2.webp'
import img12 from '../../images/rooms_gallery/Classic Triple Room/3.webp'

import img13 from '../../images/rooms_gallery/Double Room/1.webp'
import img14 from '../../images/rooms_gallery/Double Room/2.webp'
import img15 from '../../images/rooms_gallery/Double Room/3.webp'
import img16 from '../../images/rooms_gallery/Double Room/4.webp'
// import img16 from '../../images/rooms_gallery/Double Room/5.webp'

import img17 from '../../images/rooms_gallery/Double Room with Balcony/1.webp'
import img18 from '../../images/rooms_gallery/Double Room with Balcony/2.webp'
import img19 from '../../images/rooms_gallery/Double Room with Balcony/3.webp'

import img20 from '../../images/rooms_gallery/Family Room/1.webp'
import img21 from '../../images/rooms_gallery/Family Room/2.webp'
import img22 from '../../images/rooms_gallery/Family Room/3.webp'
import img23 from '../../images/rooms_gallery/Family Room/4.webp'

import img24 from '../../images/rooms_gallery/Suite with Balcony/1.webp'
import img25 from '../../images/rooms_gallery/Suite with Balcony/2.webp'
import img26 from '../../images/rooms_gallery/Suite with Balcony/3.webp'
// import img26 from '../../images/rooms_gallery/Suite with Balcony/4.webp'
import img27 from '../../images/rooms_gallery/Suite with Balcony/5.webp'
import img28 from '../../images/rooms_gallery/Suite with Balcony/6.webp'
import img29 from '../../images/rooms_gallery/Suite with Balcony/7.webp'
import img30 from '../../images/rooms_gallery/Suite with Balcony/8.webp'

import img31 from '../../images/rooms_gallery/win Room with Extra Bed/1.webp'
import img32 from '../../images/rooms_gallery/win Room with Extra Bed/2.webp'
import img33 from '../../images/rooms_gallery/win Room with Extra Bed/3.webp'
import img34 from '../../images/rooms_gallery/win Room with Extra Bed/4.webp'
import img35 from '../../images/rooms_gallery/win Room with Extra Bed/5.webp'
import img36 from '../../images/rooms_gallery/win Room with Extra Bed/6.webp'
const data = {
  text: {
    en: {
      0: "Basic double room",
      1: "Double room",
      2: "Double room with balcony",
      3: "Family room",
      4: "Family suite with two room",
      5: "Room with bunk bed",
      6: "Room with bunk bed",
      7: "Triple room",
      8: "Twin room with balcony",
    },
    ru: {
      0: "комната на двухместный",
      1: "комната на двухместный",
      2: "Двухместный номер с балконом с одной кроватью",
      3: "Семейный номер",
      4: "Семейный люкс с двумя комнатами",
      5: "Номер с двухъярусной кроватью",
      6: "Комната с двухъярусной кроватью ",
      7: "Трехместный номер",
      8: "Двухместный номер с балконом",
    },
  },
  btn: {
    en: "Take a look",
    ru: "Просматривать"  },
  price: [90, 90, 100, 110, 120, 80, 80, 100, 100],
  images: [first, second, third, fourth, fifth, sixth, seventh, eigtht, ninth],
  gallery: [
    [img2, img3,img1, img4],
    [img14, img15, img16, img13],
    [img17, img18, img19],
    [img20, img21, img22, img23],
    [img24, img25, img27, img28, img29, img30, img26],
    [img5, img7, img9, img6, img8],
    [img5, img9, img6, img6, img8],
    [img10, img12],
    [img33, img36, img34],
  ],
};

export default data;
