import { createContext } from "react";

const savedLanguage = localStorage.getItem('language')

const LanguageContext = createContext({
	language: savedLanguage === null ? 'en' : savedLanguage,
	setLanguage: () => { }

});

export default LanguageContext;
