import styles from "./footer.module.css";
import { FaHome, FaPhoneAlt, FaViber } from "react-icons/fa";
import { IoLogoWhatsapp } from 'react-icons/io'

const Footer = () => {
  return (
    <footer className={styles.container}>
      <article className={styles.info}>
        <h1>Georgia, Tbilisi</h1>

        <div className={styles.numbers}>
          <div>
            <FaHome className={styles.icon} />
            <h3>Nioradze street 19</h3>
          </div>

          <div className={` ${styles.number}`}>
            <div>
              <IoLogoWhatsapp className={`${styles.icon} ${styles.whatsapp}`} />
            </div>
            <a href="https://wa.me/995593326928">+995 593 32 69 28</a>
          </div>
          <div className={`${styles.number}`} >
            <div style={{marginLeft: '-.6rem'}}>
              <FaPhoneAlt className={styles.icon} />
            </div>
            <a href="tel:+995322770988">+995 322 77 09 88</a>
          </div>

          <div className={`${styles.number}`} >
            <div style={{marginLeft: '-.5rem'}}>
              <IoLogoWhatsapp className={`${styles.icon} ${styles.whatsapp} `} />
            </div>
            <a href="https://wa.me/79037209007">+7 903 720 900 7</a>
          </div>
        </div>
      </article>
    </footer>
  );
};

export default Footer;
