import React from "react";
import styles from "./serviceImages.module.css";

import img1 from '../../images/services/1.webp'
import img2 from '../../images/hotel_gallery/16.webp'

import img3 from '../../images/gallery/17.webp'
import img4 from '../../images/hotel_gallery/4.webp'
import img5 from '../../images/gallery/18.webp'
const data = [img1, img2,img4,  img3, img5];

const serviceImages = () => {
  return (
    <section className={styles.container}>
      {data.map((image, index) => {
        return (
          <div key={index} className={styles.item}>
            <img className={styles.image} src={image} alt="Hotel Heivany" />
          </div>
        );
      })}
    </section>
  );
};

export default serviceImages;
