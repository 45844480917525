import React from 'react';
import styles from './gallery.module.css'

import img1 from '../../images/hotel_gallery/1.webp'
import img2 from '../../images/hotel_gallery/2.webp'
import img3 from '../../images/hotel_gallery/3.webp'
import img4 from '../../images/hotel_gallery/4.webp'
import img6 from '../../images/hotel_gallery/6.webp'
// import img7 from '../../images/hotel_gallery/7.webp'
import img9 from '../../images/hotel_gallery/9.webp'
import img10 from '../../images/hotel_gallery/10.webp'
import img11 from '../../images/hotel_gallery/11.webp'
import img12 from '../../images/hotel_gallery/12.webp'
import img13 from '../../images/hotel_gallery/13.webp'
import img14 from '../../images/hotel_gallery/14.webp'
import img15 from '../../images/hotel_gallery/15.webp'
import img16 from '../../images/hotel_gallery/16.webp'
import img17 from '../../images/hotel_gallery/17.webp'
import img18 from '../../images/hotel_gallery/18.webp'
import img19 from '../../images/hotel_gallery/19.webp'
// import img20 from '../../images/hotel_gallery/20.webp'
import img21 from '../../images/hotel_gallery/21.webp'
import img22 from '../../images/hotel_gallery/22.webp'
import img23 from '../../images/hotel_gallery/23.webp'
import img24 from '../../images/hotel_gallery/24.webp'
import img25 from '../../images/hotel_gallery/25.webp'
import img26 from '../../images/hotel_gallery/26.webp'
import img27 from '../../images/hotel_gallery/27.webp'
import img28 from '../../images/hotel_gallery/28.webp'
import img29 from '../../images/hotel_gallery/29.webp'
import img31 from '../../images/hotel_gallery/31.webp'
// import img33 from '../../images/hotel_gallery/33.webp'
import img34 from '../../images/hotel_gallery/34.webp'
import img37 from '../../images/hotel_gallery/37.webp'
// import img39 from '../../images/hotel_gallery/39.webp'
import img41 from '../../images/hotel_gallery/41.webp'
import img43 from '../../images/hotel_gallery/43.webp'
import img44 from '../../images/hotel_gallery/44.webp'
import img45 from '../../images/hotel_gallery/45.webp'

import image4 from '../../images/gallery/4.webp'
import image9 from '../../images/gallery/9.webp'
import image10 from '../../images/gallery/10.webp'
import image16 from '../../images/gallery/16.webp'
import image17 from '../../images/gallery/17.webp'
import image18 from '../../images/gallery/18.webp'
import image19 from '../../images/gallery/19.webp'
import image21 from '../../images/gallery/21.webp'
import image22 from '../../images/gallery/22.webp'
import image23 from '../../images/gallery/23.webp'
import image24 from '../../images/gallery/24.webp'
import image25 from '../../images/gallery/25.webp'
import image26 from '../../images/gallery/26.webp'

const data = [
	img1, img3, img4, img6, img9, img10, img11, img12, img13, img14, img15, img27, img16, img17,
	img18, img19, img21, img22, img23, img24, img26, img27,
	img28, img29, img2, img31, img34, img37,
	img43, img44, img45, image4,
	img25, img41, image9, image10,
	image16, image17, image18, image19, image21, image22, image23, image24, image25, image26]
const Gallery = () => {
	return <section className={styles.container}>

		{data.map((img, i) => <img className={styles.item} src={img} key={i} loading="lazy" alt="hotel gallery" />)}

	</section>
}

export default Gallery
