const text = {
	en: {
		weekendH: "Georgian weekend",
		weekendP1: "Usually this would be a three day trip, but we can be flexible increasing or decreasing the number of days depending on your preferences.",
		weekendP2:`The first day would be spent around the Old Town. On the second day we would tell you all about the old capital of Georgia Mtshete, a cave town of Uplistsikhe and other UNESCO heritage sights. Third day would focus on the Eastern wine region of Georgia, Kakheti, where you could sample the beautiful wines whilst emerging yourself in further sightseeing with a fifth century fortress and Chavchavadze estate with it’s museum and gardens.`,
		weekendP3: 'We will take you to the love-town Signakhi where you will be astound by the breathtaking views on Alazan Valley.',
		palmH: "Georgia on a palm",
		palmP1: 'From the height of 2200m above sea level you will discover the stunning views, diverse nature and historic places. This tour will take you straight to the main mountain Kazbek which we will reach on a 4×4 using the former military Georgian road. Magnificent fortress Anauri and Gergtskaya Troitsa will completely take over your imagination.',
		palmP2:
			"Our guests particularly enjoy the wine museum in Napareuli where the traditional method of wine-making (kveri) is still being kept alive. Naturally this would include wine tasting during the vineyards tour and visiting wine cellars.",
		palmP3: 'We will then move further down Souch to discover a town in caves, a fortress which has once been visited by Makedonski and get some rest and relaxation in the famous town of Borjomi with its natural springs.',
		individualH: "Individual tours",
		individualP1:
			"We will talk you through all sightseeing opportunities in Georgia and help you choose the most appealing ones. You can leave further planning to us and we will make your wishes come true.",
			individualP2: 'To receive a free quote and a competitive price please email us with your questions, requests and preferences and we will be delighted to show you our beautiful country at a pace and time that suit you.',
	},
	ru: {
		weekendH: "Выходные в Грузии",
		weekendP1: "Данный тур рассчитан на 3 экскурсионных дня ( время и содержание программы возможно корректировать.).",
		weekendP2:`
Первый день посвящен подробному знакомству с достопримечательностями старого города. Второй день будет насыщен поездками по древней столице Грузии Мцхете, городу в скале Уплисцихе и посещением мест, внесенных в список наследия ЮНЕСКО. Третий день: поездка в восточный уголок виноделия – Кахетию, также мы увидим Крепость V века, поместье Чавчавадзе, его музей и сад.`,
		weekendP3: 'Посещение города любви Сигнахи с видом на Алазанскую долину.',
		palmH: "Грузия как на ладони",
		palmP1: 'Невероятной красоты виды, богатая природа и ее достопримечательности открываются нам с высоты 2 200 метров. Экскурсионный тур в горы, к главной горе Казбек, куда мы отправляемся на внедорожнике по военно-грузинской дороге. Величайшая крепость Ананури, Гергетская троица своим величием не смогут оставить равнодушными никого.',
		palmP2:
			"Особо полюбившимся местом наших гостей является музей вина в Напареули, где процветает традиционный метод изготовления вина – квеври. Дегустационный тур по виноделиям, посещение винных погребов.",
		palmP3: "Далее наше знакомство с Грузией продолжится в Южной ее части: пещерный город, крепость, которую посещал сам А. Македонский,  и отдых в курортном городе Боржоми с его знаменитыми источниками.",
		individualH: "Индивидуальная программа",
		individualP1:
			"Мы предлагаем вам ознакомиться со всеми достопримечательностями, которыми так богата Грузия, выбрать наиболее интересные для вас, и мы составим тур по вашим пожеланиям.",
			individualP2: 'Для более полного ознакомления с экскурсионными программами вам необходимо написать нам о своих предпочтениях, мы с удовольствием ответим на все вопросы и подберем тур, соответствующий вашим пожеланиям и интересам.',
	},
};

export default text;
