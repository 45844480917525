const text = {
	en: {
		airH: 'Airport transfer',
		airP: 'Please let us know if you would like us to meet you at the airport and take you back at the end of your stay. We would be happy to organise your transfer with our trusted drivers.',
		siteH: 'Onsite parking',
		siteP: 'If you are coming by car or are planning to rent a car in Georgia please let us know. We will reserve your free parking space at the hotel and can also help you with the car rental arrangements. Homemade',
		bfH: "Breakfast",
		bfP: 'Breakfast is the most important meal of the day, especially if you are planning a long day out. We will cook your breakfast to order depending on what you like: traditional hot bread with cheese (hatchapuri), porridge, fried eggs, omelet or sandwiches. Or you can simply go for a range of cereals. It goes without saying that we provide freshly brewed coffee and tea.',
		laundryH: 'Laundry service',
		laundryP: 'To save yourself some time and effort, please tell Reception what you need washed or ironed and we will do it for you as quickly as possible.',
		eveningH: 'Evenings in the garden',
		eveningP: 'Please enjoy our beautiful outdoor space in the authentic wooden alcove with comfortable seats and armchairs. We offer a choice of alcoholic and nonalcoholic beverages as well as a variety of snacks. For cooler nights we have wraps and throws as well as natural herbal teas to keep you warm. Georgian wine, cosy wooden alcove and a quietly running water fountain will make every evening beautiful.',
		nightH: 'Traditional Georgian night',
		nightP: 'We would be delighted to cook you a real traditional Georgian dinner served in the alcove. In Georgia we are passionate about cooking and sharing food and we especially hope to impress you with a selection of meat (shashlik) grilled on the open fire and bread that is baked as you watch in a traditional oven.',
		workshopH:'Workshops',
		workshopP:'Children and adults alike love participating in our workshops making churchhela, bread or hatchapuri. We also invite you to participate in the kveri (ceramic pot burried underground) opening ceremony to sample real wine produced according to the ancient Georgian recipe.',
		tourH:'Tours',
		tourP:'We cater for all requirements and can help you plan and organise tours to any part of Georgia.',
		wineH: 'Georgian wine tasting',
		wineP: 'Wine sampling Our experienced wine expert will help you find the perfect Georgian wine. Fruity and blossom aromas of Georgian homemade wine simply cannot be even the most demanding experts.',
		souvenirH: 'Souvenirs',
		souvenirP: 'Souvenirs and gifts If you want something special to take home let us know and we will strive to help you find it!',
	},
	ru: {
		airH: 'Трансфер',
		airP: 'При бронировании сообщите нам о желании воспользоваться трансфером – мы с большим удовольствием встретим и проводим вас.',
		siteH: 'Парковка',
		siteP: 'Если вы пожелаете приехать к нам на личном транспорте – сообщите нам об этом, у нас есть удобная парковка. Возможно, вы захотите арендовать автомобиль на время вашего путешествия – мы с удовольствием поможем Вам в этом вопросе.',
		bfH: "Домашние завтраки",
		bfP: 'Перед долгим насыщенным днём необходимо хорошо подкрепиться.Горячий хачапури,каша на свежем молоке, сендвичи в компании свежесваренного кофе и многое другое ждёт вас на завтрак.',
		laundryH: 'Услуги прачечной (clean service)',
		laundryP: 'Бережём ваше время. Сообщите на ресепшен, что у вас есть вещи для стирки или требуется что-то погладить, и в кратчайшие сроки мы выполним ваш заказ.',
		eveningH: 'Вечера в саду',
		eveningP: 'Украшением нашего двора является деревянная беседка в старинном грузинском стиле с удобными лавками и креслами. Для наших гостей мы предлагаем широкий выбор алкогольных и безалкогольных напитков, а также разнообразные закуски. Для прохладных вечеров предусмотрены пледы и ароматный травяной чай. Грузинское вино, уютная деревянная беседка и успокаивающее журчание фонтана сделают каждое окончание дня прекрасным.',
		nightH: 'Вечер в грузинском стиле',
		nightP: 'По вашему желанию мы можем приготовить и накрыть в беседке настоящий грузинский ужин. Вас не оставит равнодушным шашлык, пожаренный в настоящем камине на открытом огне, а особое удовольствие вам доставит свежий, ароматный хлеб, испеченный при вас в тандыре – удивительной национальной печке. Также вы можете принять участие в церемонии открытия квеври (глиняный кувшин в земле) и попробовать вкус настоящего деревенского вина, изготовленного по старинной грузинской технологии.',
		workshopH:'Мастерклассы',
		workshopP:'Приглашаем вас принять участие в милом и приятном развлечении, которое понравится и детям и взрослым. Предлагаем вашему вниманию мастерклассы по изготовлению чурчхелы, выпечке хлеба в тандыре и изготовлению хачапури.',
		tourH:'Экскурсия и туры',
		tourP:'Подборка и организация экскурсий по городу и туров по всей Грузии любой сложности.',
		wineH: 'Дегустация  грузинского вина',
		wineP: 'Найти наиболее близкий вам вкус жемчужины Грузинской культуры поможет опытный сомелье.Фруктовые и цветочные ноты домашнего виноделия не оставят равнодушными даже для самых взыскательных ценителей.',
		souvenirH: 'Сувениры и подарки',
		souvenirP: 'Мы рады помочь вам в поиске особенных подарков и сувениров из Грузии.',
	}
};

export default text;
