import React from "react";
import styles from "./services.module.css";
import LanguageContext from "../../context/context";
import text from "./text";
const Services = (props) => {
  return (
    <LanguageContext.Consumer>
      {({ language }) => {
        return (
          <section className={styles.container}>
            <div className={styles.item}>
              <h2>{text[language].airH}</h2>
              <p>{text[language].airP}</p>
            </div>
            <div className={styles.item}>
              <h2>{text[language].siteH}</h2>
              <p>{text[language].siteP}</p>
            </div>

            <div className={styles.item}>
              <h2>{text[language].bfH}</h2>
              <p>{text[language].bfP}</p>
            </div>
            <div className={styles.item}>
              <h2>{text[language].laundryH}</h2>
              <p>{text[language].laundryP}</p>
            </div>

            <div className={styles.item}>
              <h2>{text[language].eveningH}</h2>
              <p>{text[language].eveningP}</p>
            </div>
            <div className={styles.item}>
              <h2>{text[language].nightH}</h2>
              <p>{text[language].nightP}</p>
            </div>
            <div className={styles.item}>
              <h2>{text[language].workshopH}</h2>
              <p>{text[language].workshopP}</p>
            </div>
            <div className={styles.item}>
              <h2>{text[language].tourH}</h2>
              <p>{text[language].tourP}</p>
            </div>
            <div className={styles.item}>
              <h2>{text[language].wineH}</h2>
              <p>{text[language].wineP}</p>
            </div>
          </section>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Services;
